<template src="./templates/login-page.html"></template>
<script>

	import PageTitle from '../../components/PageTitle/index';
	import LoginForm from './components/Login/index';

	export default {
		name: 'LoginPage',
		components: {
			PageTitle,
			LoginForm
		}
	};
</script>
